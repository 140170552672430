// Import components related to projects
import Projects from "./index";
import ProjectsDetails from "./details.vue";
// Export an array of route configurations
export default [
  {
    // Path for the projects listing route
    path: "/projects",
    // Component to render when this route is accessed
    component: Projects,
    // Name for this route
    name: "Projects",
  },
  {
    // Path for viewing project details (with an ID parameter)
    path: "/project/:id",
    // Component to render when this route is accessed
    component: ProjectsDetails,
    // Name for this route
    name: "Project-Details",
  },
];
