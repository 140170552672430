<template>
  <div>
    <!-- Page header with icon and title -->
    <PageHeader :icon="'ri-vip-crown-line h2'" :title="title" :items="items" />
    <!-- Container for subscription plan cards -->
    <div>
      <b-row>
        <!-- Subscription plan cards -->
        <b-col v-for="(plan, index) in subscriptionPlanData" :key="index">
          <b-card class="custom-card custom-shadow">
            <span :class="iconClass(plan.title)"></span>
            <h6>{{ plan.title }}</h6>
            <b-col>
              <p>{{ plan.adminCount }} Admin</p>
              <p>{{ plan.userCount }} User</p>
            </b-col>
            <button class="btn btn-primary btn-sm" @click="editPlan(plan)">
              EDIT
            </button>
          </b-card>
        </b-col>
      </b-row>
      <!-- Modal for editing subscription plan -->
      <b-modal
        id="editSubscription"
        v-model="modalShow"
        centered
        scrollable
        size="sm"
        hide-footer
        title="Edit Subscription plan"
      >
        <form action="#" @submit.prevent="updatePlan()">
          <!-- Form fields for editing subscription plan -->
          <div class="form-group">
            <label>Admin <span class="text-danger">*</span></label>
            <input
              v-model="formData.adminCount"
              type="number"
              class="form-control"
              name="admin"
              required
            />
          </div>
          <div class="form-group">
            <label>Users <span class="text-danger">*</span></label>
            <input
              v-model="formData.userCount"
              type="number"
              class="form-control"
              name="user"
              required
            />
          </div>
          <div class="form-group mb-0">
            <div class="text-center">
              <button type="submit" class="btn btn-primary btn-sm">
                Submit
              </button>
              <button type="reset" class="btn btn-secondary m-l-5 ml-1 btn-sm">
                Reset
              </button>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<style scoped>
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}
.custom-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 23;
}
.custom-card {
  /* margin-bottom: 20px; */
  text-align: center;
  padding-right: 0px;
  padding-left: 0px;
}
.ri-stack-fill:before,
.ri-loader-line,
.ri-star-line,
.ri-building-line {
  color: #0f3460;
  font-size: 90px;
}
@media (max-width: 575.98px) {
  .custom-card {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .custom-card {
    margin-right: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-card {
    margin-right: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-card {
    margin-right: 15px;
  }
}
</style>
<script>
import PageHeader from "@/components/pageheader";
import { eventBus } from "../../../main";
export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      title: "Subscription",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Subscription",
          active: true,
        },
      ],
      subscriptionPlanData: [],
      modalShow: false,
      id: null,
      formData: {
        title: "",
        adminCount: null,
        userCount: null,
      },
    };
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.subscription.text");
    if (Object.keys(this.$store.state.Auth.subPlanData).length) {
      this.subscriptionPlanData = this.$store.state.Auth.subPlanData;
    } else {
      this.getSubscriptionPlans();
    }
  },
  computed: {
    iconClass() {
      return (icon) => {
        switch (icon) {
          case "Basic":
            return "ri-stack-fill";
          case "Silver":
            return "ri-loader-line";
          case "Gold":
            return "ri-star-line";
          case "Enterprise":
            return "ri-building-line";
          default:
            return "";
        }
      };
    },
  },
  methods: {
    async getSubscriptionPlans() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get("/serveradmin/subscriptionplan/")
        .then((response) => {
          if (response.data) {
            this.subscriptionPlanData = response.data;
            this.$store.commit("setSubplanData", this.subscriptionPlanData);
          } else {
            this.messageToast(
              "Error",
              "warning",
              "No subscription plans found"
            );
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching subscription plans"
          );
        });
    },
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    editPlan(item) {
      this.modalShow = true;
      this.formData = item;
      this.id = item.id;
    },
    updatePlan() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .put(`/serveradmin/subscriptionplan?planId=${this.id}`, this.formData)
        .then(() => {
          this.modalShow = false;
          this.messageToast(
            "Success",
            "success",
            "Subscription plan has been changed successfully"
          );
          this.getSubscriptionPlans();
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "Unable to change the subscription plan. Please try again later."
          );
        });
    },
  },
};
</script>
