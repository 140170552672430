// Import the Dashboard component from "./index"
import Dashboard from "./index";

// Export an array of route configurations
export default [
  {
    // Path for this route
    path: "/dashboard",
    // Component to render when this route is accessed
    component: Dashboard,
    // Name for this route
    name: "Dashboard"
  }
];
