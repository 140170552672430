<script>
/**
 * Sources Component
 */
export default {
  data() {
    return {};
  },
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-3">Features</h4>
      <div data-simplebar style="max-height: 18vh;  padding-bottom: 15px;">
        <div class="table-responsive mt-0">
          <table class="table  mb-0 table-centered table-nowrap">
            <tbody>
              <tr>
                <td class="d-flex align-items-center">
                  <span class="mdi mdi-file-document-outline pr-2"></span>
                  <h5 class="font-size-14 mb-0">Doc Manager</h5>
                </td>
              </tr>
              <tr>
                <td class="d-flex align-items-center">
                  <span class="mdi mdi-file-document-outline pr-2"></span>
                  <h5 class="font-size-14 mb-0">Doc Editor</h5>
                </td>
              </tr>
              <tr>
                <td class="d-flex align-items-center">
                  <span class="mdi mdi-file-document-outline pr-2"></span>
                  <h5 class="font-size-14 mb-0">Doc Styler</h5>
                </td>
              </tr>
              <tr>
                <td class="d-flex align-items-center">
                  <span class="mdi mdi-file-document-outline pr-2"></span>
                  <h5 class="font-size-14 mb-0">Doc Publisher</h5>
                </td>
              </tr>
              <tr>
                <td class="d-flex align-items-center">
                  <span class="mdi mdi-file-document-outline pr-2"></span>
                  <h5 class="font-size-14 mb-0">Doc Migration</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table td {
  padding: 0rem;
}

.mdi {
  font-size: 25px;
}

.card {
  height: 25vh;
  overflow: hidden;
}
</style>
