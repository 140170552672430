<template>
  <b-card
    class="p-0 org-card"
    :class="{
      // Apply 'deActive' class when item is not active
      deActive: !item.isActive,
      // Apply 'warningCard' class when months are less than or equal to 2
      warningCard: months <= 2,
      // Apply 'dangerCard' class when months are greater than 0 and less than or equal to 1
      dangerCard: 1 >= months > 0,
    }"
  >
    <b-row class="justify-content-between mx-0 header p-3">
      <div class="d-flex text-primary">
        <span><i class="mdi mdi-domain"></i></span>
        <span class="title ml-2"> {{ item.name }} </span>
      </div>
    </b-row>
    <div class="p-3 details">
      Organization Id: <span class="text-muted">{{ item.customId }}</span>
      <br />
      DITA-OT: <span class="text-muted">{{ item.ditaotVersion }}</span>
    </div>
    <b-row class="justify-content-between mx-0 px-3 py-2 footer-details">
      <span>
        <span class="badge badge-soft-primary mr-2">Projects:</span>
        <span class="badge badge-soft-primary mr-2">{{
          projectList.length
        }}</span>
        <span class="badge badge-soft-primary mr-2">Users:</span>
        <span class="badge badge-soft-primary">{{ userList.length }}</span>
      </span>
      <span> </span>
    </b-row>
    <hr class="m-0" />
    <b-row class="justify-content-between mx-0 px-3 py-2 ">
      <span></span>
      <span>
        <span
          @click="toOrgDetails(item.id, item.customId)"
          v-if="item.isActive"
          role="button"
          class=" mr-2"
        >
          <span><i class="mdi mdi-eye text-primary mr-1"></i></span>View
        </span>
        <span
          @click="toEditOrg(item.id)"
          v-if="item.isActive"
          role="button"
          class=" mr-2"
        >
          <span><i class="mdi mdi-pencil text-primary mr-1"></i></span>Edit
        </span>
        <span
          @click="activate(item)"
          v-if="!item.isActive"
          role="button"
          class=" mr-2"
        >
          <span
            ><i
              class="mdi mdi-account-check-outline text-primary mr-1"
            ></i></span
          >Activate
        </span>
        <span @click="deActivateModal(item)" v-else role="button" class=" mr-2">
          <span
            ><i
              class="mdi mdi-account-cancel-outline text-primary mr-1"
            ></i></span
          >Deactivate
        </span>
      </span>
    </b-row>
    <b-modal
      v-model="modalShow"
      centered
      title="Tell us the reason for deactivate"
      title-class="font-18"
      hide-footer
    >
      <div class="mt-lg-0">
        <h5 class="font-size-14 mb-4">Select option</h5>
        <b-form-radio-group
          v-model="reason"
          :options="options"
          class="mb-3 d-flex flex-column gap-y-3"
          value-field="id"
          text-field="title"
          required
        ></b-form-radio-group>
        <div class="form-group" v-if="reason == 'Other'">
          <textarea type="text" class="form-control" name="reason" />
        </div>
      </div>
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="deActivate()"
        >
          Deactivate
        </button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      modalShow: false,
      projectList: [],
      userList: [],
      reason: null,
      selectedItem: null,
      months: null,
      options: [
        "Don't need this organization any more",
        "Created duplicate organization",
        "Other",
      ],
    };
  },
  mounted() {
    this.getProjectList();
    this.getUserList();
    this.CheckValidity();
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    toOrgDetails(item, customId) {
      const id = CryptoJS.AES.encrypt(item, secretKey).toString();
      const customid = CryptoJS.AES.encrypt(customId, secretKey).toString();
      const encodedId = encodeURIComponent(id);
      const encodedcustomId = encodeURIComponent(customid);
      this.$router.push(`/organization/${encodedId}/${encodedcustomId}`);
    },
    toEditOrg(item) {
      const encrypItem = CryptoJS.AES.encrypt(item, secretKey).toString();
      const encodedItem = encodeURIComponent(encrypItem);
      this.$router.push(`/edit-organization/${encodedItem}`);
    },
    activate(item) {
      let orgActiveObj = {};
      orgActiveObj.orgId = item.id;
      orgActiveObj.reason = "null";
      this.$store.getters.client
        .put(`/serveradmin/organization/active`, orgActiveObj)
        .then((response) => {
          if (response.data && response.data.message) {
            item.isActive = true;
            this.$bvToast.toast(response.data.message, {
              title: `Activated`,
              variant: "success",
              solid: true,
            });
          } else {
            this.messageToast(
              "Error",
              "warning",
              "Activation failed. Please try again later."
            );
          }
        })
        .catch(() => {
          this.messageToast(
            "Error",
            "warning",
            "An error occurred during activation. Please try again later."
          );
        });
    },
    deActivateModal(item) {
      this.modalShow = true;
      this.selectedItem = item;
    },
    deActivate() {
      if (this.reason !== null) {
        let orgObject = {};
        orgObject.orgId = this.selectedItem.id;
        orgObject.reason = this.reason;
        this.$store.getters.client
          .put(`/serveradmin/organization/deactive/`, orgObject)
          .then((response) => {
            if (response.data && response.data.message) {
              this.selectedItem.isActive = false;
              this.$bvToast.toast(response.data.message, {
                title: `Deactivated`,
                variant: "success",
                solid: true,
              });
              this.modalShow = false;
            } else {
              this.messageToast(
                "Error",
                "warning",
                "Deactivation failed. Please try again later."
              );
            }
          })
          .catch(() => {
            this.messageToast(
              "Error",
              "warning",
              "An error occurred during deactivation. Please try again later."
            );
          });
      }
    },
    async getUserList() {
      if (!this.item.isActive) return;
      await this.$store.getters.client
        .get(`/orguser/byorgid?orgId=${this.item.id}`, {
          headers: {
            orgId: this.item.id,
          },
        })
        .then((response) => {
          if (response.data) {
            this.userList = response.data;
          }
        })
        .catch(() => {
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching user list"
          );
        });
    },
    async getProjectList() {
      if (!this.item.isActive) return;
      await this.$store.getters.client
        .get(`/project/byorgid?orgId=${this.item.id}`, {
          headers: {
            orgId: this.item.id,
          },
        })
        .then((response) => {
          if (response.data) {
            this.projectList = response.data;
          } else {
            this.messageToast("Error", "warning", "No project list found");
          }
        })
        .catch(() => {
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching project list"
          );
        });
    },
    CheckValidity() {
      let b = new Date(this.item.createdAt);
      let a = b.setFullYear(b.getFullYear() + 1);
      let expiryDate = new Date(a);
      let now = new Date();
      this.months = (expiryDate.getFullYear() - now.getFullYear()) * 12;
      this.months += expiryDate.getMonth() - now.getMonth();
    },
  },
};
</script>
<style lang="scss">
.badge-soft-primary {
  background-color: rgba(207, 222, 233, 1) !important;
}

.org-card {
  overflow: hidden;

  .card-body {
    padding: 0;
  }

  .header {
    background: rgba(207, 222, 233, 1);
    color: #fff;

    .title {
      font-weight: 700;
      font-size: 15px;
    }
  }

  .details {
    color: #000;
    font-weight: 700;
  }

  .b-dropdown {
    button {
      border: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;

      &:active {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .b-dropdown.show {
    button {
      border: 0;
      padding: 0;
      background: transparent;
      box-shadow: none;

      &:active {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  &.deActive {
    .header {
      background: rgba(23, 37, 146, 0.45);
    }

    .details,
    .footer-details {
      opacity: 0.4;
      background: #f1f1f1;
    }
  }

  &.warningCard {
    .header {
      background-color: rgba(#fcb92c, 1);
    }
  }

  &.dangerCard {
    .header {
      background-color: rgba(#ff3d60, 1);
    }
  }

  .gap-y-3 div {
    margin-bottom: 0.75rem !important;
  }
}
</style>
