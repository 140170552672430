<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Table</h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select
              >&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table
          :items="transactionData"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Transactions component
 */
export default {
  data() {
    return {
      transactionData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "_id", // Assuming you want to sort by the "_id" field initially
      sortDesc: false,
      fields: [
        { key: "name", sortable: true, label: "Organization name" },
        { key: "totalProjects", sortable: true, label: "Total project count" },
        { key: "activeUsersCount", sortable: true, label: "Active users" },
        { key: "inactiveUsersCount", sortable: true, label: "Inactive users" },
        { key: "totalAdmins", sortable: true, label: "Admin count" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.getOrganizationData();
    this.totalRows = this.transactionData.length;
  },
  methods: {
    async getOrganizationData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const response = await this.$store.getters.client.get(
          "/serveradmin/organization/usercounts"
        );
        loader.hide();
        this.transactionData = response.data;
      } catch (error) {
        loader.hide();
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.card {
  height: 50vh;
}
</style>
