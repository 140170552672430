<template>
  <div>
    <!-- Page header with icon, title, and items -->
    <PageHeader :icon="'ri-building-4-line h3'" :title="title" :items="items" />
    <!-- Container for the page content with a fluid layout and custom class -->
    <div fluid="xl" class="orglist-page">
      <div class="d-flex align-items-center">
        <!-- Input for searching organizations -->
        <b-form-input
          class="w-50 search-input  btn-sm"
          v-model="search"
          placeholder="Search Organizations"
          @input="searchOrg"
          v-if="organizationDataSearch.length > 0"
        ></b-form-input>
        <!-- Button to add a new organization -->
        <button class="btn-sm ml-2 btn btn-primary" @click="toAddOrg">
          Add Organization
        </button>
      </div>

      <!-- Row for displaying cards of organizations -->
      <b-row class="mt-4">
        <b-col
          cols="12"
          md="6"
          lg="4"
          v-for="item in paginatedCards"
          :key="item.id"
        >
          <!-- Display individual organization card using a component called Card -->
          <Card :item="item" />
        </b-col>
      </b-row>
      <div class="row">
        <div class="col" v-if="organizationData.length > 6">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- Pagination component for navigating through pages of organizations -->
              <b-pagination
                prev-class="light"
                v-model="currentPage"
                :total-rows="organizationData.length"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./components/card.vue";

import PageHeader from "@/components/pageheader";
import { eventBus } from "../../../main";
export default {
  name: "orgList",
  components: {
    Card,

    PageHeader,
  },
  data() {
    return {
      title: "Organizations",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Organization",
          active: true,
        },
      ],
      organizationData: [],
      organizationDataSearch: [],
      currentPage: 1,
      perPage: 6,
      search: "",
    };
  },
  computed: {
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.organizationData.slice(start, end);
    },
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.organizations.text");
    if (this.$store.state.Auth.organizationData.length) {
      this.organizationData = this.$store.state.Auth.organizationData;
      this.organizationDataSearch = this.$store.state.Auth.organizationData;
    } else {
      this.getOrganizationData();
    }
    this.totalRows = this.organizationData.length;
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    toAddOrg() {
      this.$router.push("/add-organization");
    },
    async getOrganizationData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get("/serveradmin/organization/")
        .then((response) => {
          if (response.data) {
            this.organizationData = response.data;
            this.organizationDataSearch = response.data;

            this.$store.commit("setorganizationData", this.organizationData);
          } else {
            this.messageToast("Error", "warning", "No organization data found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching organization data"
          );
        });
    },
    searchOrg() {
      if (this.search) {
        this.organizationData = this.organizationDataSearch.filter((org) => {
          return org.name.toLowerCase().includes(this.search);
        });
      } else {
        this.organizationData = this.organizationDataSearch;
      }
    },
  },
};
</script>

<style lang="scss">
.orglist-page .costum-btn {
  background: #021559;
  border: 1px solid #5664d2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  padding: 8px 15px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba($color: #021559, $alpha: 0.8);
  }
}

.page-item.active .page-link {
  background: #006d5b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.page-item .page-link {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
