<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">Statistics</h4>
            <div class="text-center">
                <div class="row">
                    <div class="col-sm-6">
                        <div>
                            <div class="mb-3">
                                <apexchart class="apex-charts" height="60" wight="60" dir="ltr" :series="userData.series"
                                    :options="userData.chartOptions"></apexchart>
                            </div>
                            <div>
                                <p class="text-muted text-truncate mb-2">Users</p>
                                <h5 class="mb-0">{{ userData.totalUsers }}</h5>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="mt-5 mt-sm-0">
                            <div class="mb-3">
                                <apexchart class="apex-charts" height="60" wight="60" dir="ltr" :series="adminData.series"
                                    :options="adminData.chartOptions"></apexchart>
                            </div>
                            <p class="text-muted text-truncate mb-2">Admin</p>
                            <h5 class="mb-0">{{ adminData.totalAdmins }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            userData: {
                series: [0], // Initialize with 0
                totalUsers: '',
                chartOptions: {
                    chart: {
                        type: "radialBar",
                        wight: 60,
                        height: 60,
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#FE5E45"],
                    stroke: {
                        lineCap: "round"
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "70%"
                            },
                            track: {
                                margin: 0
                            },
                            dataLabels: {
                                show: false
                            }
                        }
                    }
                }
            },
            adminData: {
                series: [0],
                totalAdmins: '',
                chartOptions: {
                    chart: {
                        type: "radialBar",
                        wight: 60,
                        height: 60,
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ["#71A5CB"],
                    stroke: {
                        lineCap: "round"
                    },
                    plotOptions: {
                        radialBar: {
                            hollow: {
                                margin: 0,
                                size: "70%"
                            },
                            track: {
                                margin: 0
                            },
                            dataLabels: {
                                show: false
                            }
                        }
                    }
                }
            }
        };
    },
    mounted() {
        this.getOrganizationData()
    },
    methods: {
        async getOrganizationData() {
            let loader = this.$loading.show({
                loader: "dots"
            });
            try {
                const response = await this.$store.getters.client.get('/serveradmin/organization/statistics');
                loader.hide();
                // Calculate percentage of active admins and users
                const totalAdmins = response.data[0].totalAdmins;
                const activeAdmins = response.data[0].activeAdmins;
                const adminPercentage = (activeAdmins / totalAdmins) * 100;
                const totalUsers = response.data[0].totalUsers;
                const activeUsers = response.data[0].activeUsers;
                const userPercentage = (activeUsers / totalUsers) * 100;
                // Update the values with the calculated percentages
                this.userData.totalUsers = totalUsers
                this.adminData.totalAdmins = totalAdmins
                this.userData.series = [userPercentage];
                this.adminData.series = [adminPercentage];
            } catch (error) {
                loader.hide();
            }
        },
    }
};
</script>

