<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Plan Analytics</h4>
      <apexchart
        class="apex-charts"
        :height="chartHeight"
        dir="ltr"
        :series="series"
        :options="chartOptions"
      >
      </apexchart>
      <div class="row">
        <div
          v-for="(count, index) in series"
          :key="index"
          class="col-lg-3 col-md-12 mb-4"
        >
          <div class="text-center ">
            <p class="mb-2 text-truncate">
              <i :class="circleClasses[index]"></i>
              <span class="d-lg-inline d-md-inline d-sm-none">{{
                chartOptions.labels[index]
              }}</span>
            </p>
            <h5>{{ count }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
        },
        labels: ["Basic Plan", "Silver Plan", "Gold Plan", "Enterprise plan"],
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: [
          "rgba(113, 165, 203, 1)",
          "rgba(15, 52, 96, 1)",
          "rgba(79, 201, 164, 1)",
          "rgba(254, 94, 69, 1)",
        ],
      },
      circleClasses: [
        "mdi mdi-circle text-primary font-size-10 mr-1",
        "mdi mdi-circle text-secondary font-size-10 mr-1",
        "mdi mdi-circle text-tertiary font-size-10 mr-1",
        "mdi mdi-circle text-quaternary font-size-10 mr-1",
      ],
      chartHeight: 250,
    };
  },
  mounted() {
    this.getSubscriptionPlanCount();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.chartHeight = window.innerWidth <= 600 ? 200 : 330;
    },
    async getSubscriptionPlanCount() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const response = await this.$store.getters.client.get(
          "/serveradmin/organization/subscriptionplancounts"
        );
        loader.hide();
        this.updateChartData(response.data);
      } catch (error) {
        loader.hide();
      }
    },
    updateChartData(data) {
      // Assuming the API response format matches the chart data structure
      this.series = data.map((plan) => plan.totalSubscriptions);
    },
  },
};
</script>

<style scoped>
.card {
  height: 52vh;
}

.text-primary {
  color: rgba(113, 165, 203, 1) !important;
}

.text-secondary {
  color: rgba(15, 52, 96, 1) !important;
}

.text-tertiary {
  color: rgba(79, 201, 164, 1) !important;
}

.text-quaternary {
  color: rgba(254, 94, 69, 1) !important;
}

@media only screen and (max-width: 600px) {
  .card {
    height: 90vh;
  }
}
</style>
