// Import the Login component from "./login"
import Login from "./login";
import ForgotPassword from "./forgotpassword.vue";
import VerifyAccount from "./verifyaccount.vue";
import ResetPassword from "./resetpassword";
// Export an array of route configurations
export default [
  {
    // Path for this route
    path: "/",
    // Component to render when this route is accessed
    component: Login,
    // Name for this route
    name: "Login",
    // beforeEnter guard function that runs before navigating to this route
    beforeEnter: (to, from, next) => {
      // Check if a token is stored in localStorage
      const token = localStorage.getItem("token");
      if (token) {
        // Redirect to the '/dashboard' route if a token is found
        next("/dashboard");
      } else {
        // Allow navigation to the login page if there's no token
        next();
      }
    },
  },
  {
    // Forgot Password Route
    path: "/forgotpassword",
    component: ForgotPassword,
    name: "forgotpassword",
  },
  {
    // Change Password Route
    path: "/verify",
    component: VerifyAccount,
    name: "verify",
  },
  {
    // Reset Password Route
    path: "/resetpassword/:token",
    component: ResetPassword,
    name: "resetpassword",
  },
];
