<template>
<b-row>
    <!-- Row containing a single column -->
    <b-col cols="12">
        <!-- Card component for displaying release information -->
        <b-card title="Release">
            <!-- Button to trigger the modal for creating a new release -->
            <div class="text-right">
                <b-button variant="success" class="mb-3" @click="showModal = true">Create Release</b-button>
            </div>
            <!-- Table responsive container for displaying release data -->
            <div class="table-responsive mb-0">
                <!-- Table component for displaying release details -->
                <b-table sticky-header :items="tableData" :fields="fields" responsive="sm">
                </b-table>
            </div>
        </b-card>
    </b-col>
    <!-- Modal for creating a new release -->
    <b-modal id="add-user" v-model="showModal" centered scrollable size="md" hide-footer title="CREATE NEW RELEASE">
        <b-form>
            <!-- Form group for inputting the release title -->
            <b-form-group id="input-group-1" label="Release title" label-for="input-1">
                <b-form-input id="input-1" type="text" required></b-form-input>
            </b-form-group>
            <!-- Form group for inputting the tag name -->
            <b-form-group id="input-group-2" label="Tag Name:" label-for="input-2">
                <b-form-input id="input-2" required></b-form-input>
            </b-form-group>
            <!-- Form group for selecting a branch -->
            <b-form-group id="input-group-3" label="Select Branch:" label-for="input-3">
                <b-form-select id="input-3" v-model="branch" :options="branches" required></b-form-select>
            </b-form-group>
            <!-- Buttons for submitting and resetting the form -->
            <div class="form-group mb-0">
                <div class="text-right">
                    <button type="submit" class="btn btn-primary btn-sm">Submit</button>
                    <button type="reset" class="btn btn-secondary btn-sm m-l-5 ml-1">
                        Reset
                    </button>
                </div>
            </div>
        </b-form>
    </b-modal>
</b-row>
</template>
<script>
export default {
    data() {
        return {
            showModal: false,
            branch: "master",
            branches: ["Main", "master", "dev", "develop", "design"],
            tableData: [{
                    release_name: "User one",
                    tag_name: "lorem",
                    branch_name: "main"
                },
                {
                    release_name: "User one",
                    tag_name: "lorem",
                    branch_name: "main"
                },
                {
                    release_name: "User one",
                    tag_name: "lorem",
                    branch_name: "main"
                },
                {
                    release_name: "User one",
                    tag_name: "lorem",
                    branch_name: "main"
                },
                {
                    release_name: "User one",
                    tag_name: "lorem",
                    branch_name: "main"
                },
                {
                    release_name: "User one",
                    tag_name: "lorem",
                    branch_name: "main"
                },
            ],
        };
    },
};
</script>
<style>
</style>
