// Import the components related to the organization module
import Organization from "./index";
import OrganizationDetail from "./details.vue";
import AddOrg from "./addorg.vue";
// Export an array of route configurations
export default [
  {
    // Path for the organization listing route
    path: "/organization",
    // Component to render when this route is accessed
    component: Organization,
    // Name for this route
    name: "Organization",
  },
  {
    // Path for viewing organization details (with an ID parameter)
    path: "/organization/:id/:customId",
    // Component to render when this route is accessed
    component: OrganizationDetail,
    // Name for this route
    name: "Organization Details",
    // Props function to pass route parameters to the component
    props: (route) => ({
      id: decodeURIComponent(route.params.id),
      customId: decodeURIComponent(route.params.customId)
    }),
  },
  {
    // Path for adding a new organization
    path: "/add-organization",
    // Component to render when this route is accessed
    component: AddOrg,
    // Name for this route
    name: "Add Organization",
  },
  {
    // Path for editing an organization (with an ID parameter)
    path: "/edit-organization/:id",
    // Component to render when this route is accessed
    component: AddOrg,
    // Name for this route
    name: "Update Organization",
    // Props function to pass route parameters to the component
    props: (route) => ({
      id: decodeURIComponent(route.params.id),
    }),
  },
];
