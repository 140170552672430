// Import the Subscription component from "./index"
import Subscription from "./index";
// Export an array of route configurations
export default [
  {
    // Path for the subscription route
    path: "/subscription",
    // Component to render when this route is accessed
    component: Subscription,
    // Name for this route
    name: "Subscription",
  }
];
