<template>
<div>
    <Details />
</div>
</template>
<script>
import Details from './components/details.vue';
export default {
    components: {
        Details
    },
    data() {
        return {};
    },
};
</script>
8
