<template>
<div>
    <!-- Container for the main content -->
    <b-container>
        <!-- Card for tabbed content -->
        <div class="card">
            <div class="card-body">
                <!-- Vertical tab navigation -->
                <b-tabs pills vertical content-class="p-3 text-muted">
                    <!-- Tab for displaying USERS component -->
                    <b-tab active class="border-0">
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">USERS</span>
                        </template>
                        <Users />
                    </b-tab>
                    <!-- Tab for displaying COMMIT HISTORY component -->
                    <b-tab>
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="far fa-user"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">COMMIT HISTORY</span>
                        </template>
                        <Commits />
                    </b-tab>
                    <!-- Tab for displaying RELEASE component -->
                    <b-tab>
                        <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                                <i class="far fa-envelope"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">RELEASE</span>
                        </template>
                        <Release />
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </b-container>
</div>
</template>
<script>
import Users from './components/users.vue'
import Commits from './components/commits.vue'
import Release from './components/release.vue'
export default {
    components: {
        Users,
        Commits,
        Release,
    }
}
</script>
<style></style>
