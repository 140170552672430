// Import the Profile component from "./index"
import Profile from "./index";
// Export an array of route configurations
export default [
  {
    // Path for the user profile route
    path: "/profile",
    // Component to render when this route is accessed
    component: Profile,
    // Name for this route
    name: "Profile",
  }
];
