<template>
  <div>
    <!-- Display a PageHeader component with title and items -->
    <PageHeader :icon="'ri-dashboard-line h3'" :title="title" :items="items" />
    <!-- First Row: Charts, Publications, Statistics, and Notifications -->
    <b-row class="d-flex">
      <b-col cols="12" lg="5" md="6">
        <!-- Display a DataChart component -->
        <Plananalytics />
      </b-col>
      <b-col cols="12" lg="3" md="6">
        <!-- Display a Publication and Statistics component in the same column -->
        <div>
          <Features />
          <Statistics />
        </div>
      </b-col>
      <b-col cols="12" lg="4" md="6">
        <!-- Display a Notifications component -->
        <Notifications />
      </b-col>
    </b-row>
    <!-- Second Row: Table, Logs -->
    <b-row class="d-flex">
      <b-col cols="12" lg="8" md="6">
        <!-- Display a Users component -->
        <Table />
      </b-col>
      <b-col cols="12" lg="4" md="6">
        <!-- Display a Logs component -->
        <Logs />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PageHeader from "@/components/pageheader";
import Plananalytics from "./components/plananalytics.vue";
import Logs from "./components/recentlogs.vue";
import Features from "./components/features.vue";
import Table from "./components/table.vue";
import Statistics from "./components/statistics.vue";
import Notifications from "./components/notifications.vue";
import { eventBus } from "../../../main";

export default {
  components: {
    Plananalytics,
    Logs,
    Features,
    PageHeader,
    Table,
    Statistics,
    Notifications,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    // Emit an event to update the sidebar with the specified text
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
  },
};
</script>
