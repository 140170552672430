<template>
    <div>
        <PageHeader :icon="'ri-building-4-line h2'" :title="title" :items="items" />
        <div fluid="xl">
            <div class="org-details row mb-4 text-primary">
                <div class="col-md-6">
                    <p class="mb-2 mt-3">Organization ID: {{ org.customId }}</p>
                    <p>Users: {{ userList.length }}</p>
                    <div class="mt-1">
                        <span class="px-1  text-primary bg-soft-primary ">Created Date : {{ org.createdAt | moment("DD MMM YYYY") }}</span>
                        <span class="ml-3 px-1  text-secondary bg-soft-secondary ">Expiry Date :{{ org.createdAt | moment("add", "1 year", "subtract", "1 day", "DD MMM YYYY")}}</span>
                    </div>
                </div>
                <div class="col-md-6 text mt-3">
                    <p>Subscription Plan : <span class="text-secondary">{{ activePlan.title }}</span></p>
                    <div v-if="activePlan.title === 'Enterprise'">
                        <span class="admin">Admin: {{ org.numberofAdmin }}</span>
                        <span class="user ml-2">User: {{ org.numberofUser }}</span>
                    </div>
                    <div v-else>
                        <span class="admin">Admin: {{ activePlan.adminCount }}</span>
                        <span class="user ml-2">User: {{ activePlan.userCount }}</span>
                    </div>
                    <button v-b-modal.modal-xl v class="btn btn-primary btn-sm">Upgrade Plan</button>
                </div>
            </div>

            <b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted" class="btabs-height bg-white">
                <b-tab title="Projects" active>
                    <div class="mt-1">
                        <Projects></Projects>
                    </div>
                </b-tab>
                <b-tab title="Admin">
                    <div class="mt-1">
                        <Admin :adminDetails="org"></Admin>
                    </div>
                </b-tab>
                <b-tab title="Users">
                    <div class="mt-1">
                        <Users> </Users>
                    </div>
                </b-tab>
                <b-tab title="Resource">
                    <div>
                        <Summary></Summary>
                    </div>
                </b-tab>
                <b-tab title="Details">
                    <div class="mt-1">
                        <Details :organization_details="org" :plan_details="activePlan" :orgFullDetails="orgFullDetails">
                        </Details>
                    </div>
                </b-tab>
            </b-tabs>

        </div>
        <b-modal id="modal-xl" size="xl" title="Upgrade Plan" title-class="font-18" centered hide-footer>
            <upgradePlan :activeplan="activePlan" :organization="org" />
        </b-modal>
    </div>
</template>
<script>
import Users from "./components/Tabs/users.vue";
import Admin from "./components/Tabs/admin.vue";
import Summary from "./components/Tabs/summary.vue";
import Projects from "./components/Tabs/projects.vue";
import Details from "./components/Tabs/details.vue";
import upgradePlan from "./components/Tabs/upgradeplan.vue";
import PageHeader from "@/components/pageheader";
import {
    eventBus
} from "../../../main";
import CryptoJS from "crypto-js";
import {
    secretKey
} from "../../../api/global.env";
import {
    BTabs,
    BTab
} from "bootstrap-vue";
export default {
    name: "orgDetails",
    components: {
        Details,
        Users,
        Admin,
        Projects,
        Summary,
        upgradePlan,
        PageHeader,
        BTabs,
        BTab
    },
    data() {
        return {
            title: "",
            items: [{
                text: "Dashboard",
                href: "/"
            },],
            id: null,
            org: {},
            startDate: null,
            endDate: null,
            variant: "success",
            plans: [],
            activePlan: {},
            userList: [],
            activeTab: "project",
            orgFullDetails: {}
        };
    },
    mounted() {
        eventBus.$emit('update-sidebar', "menuitems.organizations.text");
        this.getOrganizationData();
        this.getFullDetails();
    },
    methods: {
        messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
            this.$bvToast.toast(messageToastContent, {
                title: messageToastTitle,
                variant: messageToastVariant,
                solid: true,
            });
        },
        async getFullDetails() {
            let loader = this.$loading.show({
                loader: "dots",
            });
            await this.$store.getters.client
                .get(`/serveradmin/organization/details?orgId=${this.id}`)
                .then((response) => {
                    if (response.data) {
                        this.orgFullDetails = response.data;
                    } else {
                        this.messageToast("Error", "warning", "No organization details found");
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                    this.messageToast("Error", "warning", "An error occurred while fetching organization details");
                });
        },
        async getOrganizationData() {
            let loader = this.$loading.show({
                loader: "dots",
            });
            await this.$store.getters.client
                .get(`/serveradmin/organization/byorgid?orgId=${this.id}`, {
                    headers: {
                        orgId: this.id
                    }
                })
                .then((response) => {
                    if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                        this.org = response.data[0];
                        this.items.push({
                            text: this.org.name.charAt(0).toUpperCase() + this.org.name.slice(1),
                            active: true
                        });
                        this.title = this.org.name.charAt(0).toUpperCase() + this.org.name.slice(1);
                        this.$store.getters.client
                            .get("/serveradmin/subscriptionplan/")
                            .then((response) => {
                                this.plans = response.data;
                                let plan = this.plans.find((item) => {
                                    return item.id === this.org.planId;
                                });
                                this.activePlan = plan;
                            });
                        this.$store.getters.client.get(`/orguser/byorgid?orgId=${this.id}`, {
                            headers: {
                                orgId: this.id
                            }
                        }).then((response) => {
                            this.userList = response.data;
                        });
                    } else {
                        this.messageToast("Error", "warning", "No organization data found");
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                    this.messageToast("Error", "warning", "An error occurred while fetching organization data");
                });
        },
    },
    created() {
        if ("id" in this.$route.params) {
            this.id = CryptoJS.AES.decrypt(
                this.$route.params.id,
                secretKey
            ).toString(CryptoJS.enc.Utf8);
        }
    },
};
</script>
<style lang="scss">
.plan {
    &:hover {
        opacity: 0.8;
    }
}

.org-details {

    h1,
    h5,
    p {
        line-height: 1.25;
        margin-bottom: 0;
    }

    h1 {
        font-weight: 600;
    }

    p {
        font-weight: 900;
    }
}

.tab-card {
    background: rgba(23, 37, 146, 0.85) !important;
    color: #fff;
    text-align: center;
    cursor: pointer;

    i {
        font-size: 20px;
    }

    h6 {
        justify-content: center;
    }
}

.tab-card.active {
    background-color: #1cbb8c !important;
}

.col-md-6.text {
    text-align: right;
}

.subscription-btn {
    width: 247px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
}
</style>
