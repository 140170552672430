// Import the Dashboard component from "./index"
import Recentreleasetable from "./recentreleasetable.vue";

// Export an array of route configurations
export default [
  {
    // Path for this route
    path: "/recentreleasetable",
    // Component to render when this route is accessed
    component: Recentreleasetable,
    // Name for this route
    name: "Recentreleasetable",
  },
];
